import type { GetServerSideProps, InferGetStaticPropsType } from 'next';
import { NextPageWithLayout } from '@/pages/_app';
import React from 'react';
import { AppSettings, lowerName } from '@/shared/app-common';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import {
  fetchHairstyleLibLabelsData,
  fetchHairstyleLibPublicData,
  getPageContentContentApi,
} from '@/lib/service';
import dynamic from 'next/dynamic';
import CustomHead from '@/components/Common/CustomHead';
import { canonicalUrl } from '@/shared/utils';
import Layout from '@/layouts';
import { mainLabelMap } from '@/common/mainLabel';
import { getTdkApi } from '@/lib/service/tdk';
import { page2JsonLd } from '@/lib/jsonld';
import { hyphenatedToTitleCase } from '@/shared/path-helper';
const HairStyleLandingHome = dynamic(() => import('@/components/HairStyleLandingHome'), {
  ssr: true,
});

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { locale, req, resolvedUrl, query } = context;
  const { id } = query;

  const [localeRes, labelRes, dataRes, pageContent, tdkData] = await Promise.all([
    serverSideTranslations(locale, ['common']),
    fetchHairstyleLibLabelsData(mainLabelMap.get(id as string), locale),
    fetchHairstyleLibPublicData(
      {
        page_no: 1,
        page_size: 30,
        gender: 'all',
        label: '',
      },
      mainLabelMap.get(id as string),
    ),
    getPageContentContentApi(
      `/${id}`,
      locale,
      Number(context.query.preview) || '',
      Number(context.query.preview_id) || '',
    ),
    getTdkApi(resolvedUrl, locale),
  ]);
  const tempData = localeRes['_nextI18Next']['initialI18nStore'][locale]['common'] ?? {};
  const jsonLd = page2JsonLd(
    {
      title: tdkData?.data?.title ?? hyphenatedToTitleCase(id.toString()),
      desc: tdkData?.data?.description ?? tempData[`${lowerName}_meta_Desc`],
      url: canonicalUrl(req.headers.host, resolvedUrl, locale),
    },
    {
      url: canonicalUrl(req.headers.host, '/', locale),
    },
  );
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      ...localeRes,
      lebelList: labelRes?.data ?? [],
      dataList: dataRes?.data ?? [],
      herf: canonicalUrl(req.headers.host, resolvedUrl, locale),
      title: tdkData?.data?.title ?? tempData[`${lowerName}_meta_Title`],
      description: tdkData?.data?.description ?? tempData[`${lowerName}_meta_Desc`],
      hasSurveyReward: Boolean(query.survey_reward),
      pathParam: {
        pathId: id,
        gender: 'all',
        label: 'all',
      },
      pageContent: pageContent?.data ?? null,
      jsonLd,
    },
  };
};

const Home: NextPageWithLayout<InferGetStaticPropsType<typeof getServerSideProps>> = (
  props: any,
) => {
  return <HairStyleLandingHome data={props} />;
};

Home.getLayout = function getLayout(page: React.ReactElement) {
  const title = page?.props?.title ?? '';
  const desc = page?.props?.description ?? '';

  return (
    <Layout
      head={
        <CustomHead>
          <title>{title}</title>
          <meta name="title" content={title} />
          <meta name="description" content={desc} />

          <meta property="og:title" content={title} />
          <meta property="og:description" content={desc} />
          <meta property="og:image" content={AppSettings.logoUrl} />
          <meta property="og:image:secure_url" content={AppSettings.logoUrl} />
          <meta property="og:type" content="summary" />
          <meta property="og:url" content={page.props?.herf} />

          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={desc} />
          <meta name="twitter:image" content={AppSettings.logoUrl} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content={page.props?.herf} />

          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="canonical" href={page.props?.herf} />
          {page.props?.jsonLd && (
            <script
              async
              type="application/ld+json"
              dangerouslySetInnerHTML={{ __html: JSON.stringify(page.props?.jsonLd) }}
            />
          )}
        </CustomHead>
      }
    >
      {page}
    </Layout>
  );
};

export default Home;
