import Head from 'next/head';
import Script from 'next/script';

const CustomHead = ({ children }: { children?: React.ReactNode }) => {
  return (
    <>
      <Head>{children}</Head>
      <Script
        id="clarity-script"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
              (function(c,l,a,r,i,t,y){
                  c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
                  t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                  y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
              })(window, document, "clarity", "script", "m0up3qplda");
          `,
        }}
      />
    </>
  );
};

export default CustomHead;
